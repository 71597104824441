<template>
  <StaffPageWrapper :config="config">
    <v-container fluid>
      <v-card
          class="card-header"
          color="primary">
        <v-card-title class="text-white">
          <strong>
            Manage Customers
          </strong>
        </v-card-title>
        <v-card-text class="text-white">
          Manage Customers, Orders, Order Items & More.
        </v-card-text>
      </v-card>
      <v-card class="card-body mt-4">
        <v-card-text>
          <v-text-field v-model="customerSearch"
                        label="Search Customers"
                        prepend-icon="mdi-magnify"
                        class="mb-4"
                        outlined>
          </v-text-field>
        </v-card-text>
      </v-card>
      <v-row justify="center"
             align="center"
             class="mt-4">
        <v-col v-for="customer in filteredCustomers"
               :key="customer.id"
               cols="auto">
          <v-card class="customer-card"
                  @click="selectCustomer(customer)">
            <v-card-text class="text-center text-white d-flex align-center">
              <v-icon left
                      color="teal lighten-2"
                      class="mr-2">mdi-account
              </v-icon>
              <strong>
                {{ customer.name }}
              </strong>
            </v-card-text>
            <v-card-subtitle class="text-white">
              {{ customer.email }}
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="selectedCustomer"
             class="mt-4">
        <v-col cols="12">
          <OrderTable :orders="selectedCustomer.orders"
                      :headers="orderHeaders"
                      :search-term="orderSearch"
                      @delete-customer="deleteCustomer">
          </OrderTable>
        </v-col>
      </v-row>
    </v-container>
  </StaffPageWrapper>
</template>

<script>
import StaffPageWrapper from "@/views/staff/components/StaffPageWrapper.vue";
import {mapGetters, mapActions} from "vuex";
import axios from "axios";
import moment from "moment";
import OrderTable from "@/views/management/customers/OrderTable.vue";

export default {
  components: {
    StaffPageWrapper,
    OrderTable,
  },
  data() {
    return {
      customers: [],
      selectedCustomer: null,
      orderHeaders: [
        {text: "Created At", value: "created_at", align: "center"},
        {text: "Items", value: "items", align: "center"},
      ],
      customerSearch: "",
      orderSearch: "",
    };
  },
  computed: {
    ...mapGetters(['config', 'user', 'loading']),
    filteredCustomers() {
      return this.customers.filter(customer =>
          customer.name.toLowerCase().includes(this.customerSearch.toLowerCase())
      );
    },
  },
  created() {
    this.fetchConfig();
    this.fetchCustomers();
  },
  methods: {
    ...mapActions(['fetchConfig']),
    async fetchCustomers() {
      const token = localStorage.getItem('token');
      const account_id = localStorage.getItem('account_id');
      try {
        const response = await axios.get('/customers', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            account_id: account_id,
          },
        });
        this.customers = response.data.map(customer => ({
          ...customer,
          orders: customer.orders.map(order => ({
            ...order,
            created_at: moment(order.created_at).format("YYYY-MM-DD HH:mm:ss"),
            items: order.items.map(item => ({
              ...item,
              created_at: moment(item.created_at).format("YYYY-MM-DD HH:mm:ss"),
            })),
          })),
        }));
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    },
    selectCustomer(customer) {
      this.selectedCustomer = customer;
    },
    async deleteCustomer(customerId) {
      const token = localStorage.getItem('token');
      try {
        await axios.delete(`/customers/${customerId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        alert('Customer deleted successfully');
        this.selectedCustomer = null;
        this.fetchCustomers();
      } catch (error) {
        console.error('Error deleting customer:', error);
      }
    },
  }
};
</script>

<style scoped>
.card-header {
  background: #2a2a2a;
  border-radius: 12px;
  padding: 20px;
  text-align: center;
}

.card-body {
  background-color: #2a2a2a;
  border-radius: 12px;
  padding: 20px;
}

.customer-card {
  cursor: pointer;
  padding: 16px;
  border-radius: 10px;
  display: inline-block;
  min-width: fit-content;
  transition: transform 0.2s, background-color 0.2s;
  background: #2a2a2a;
  text-align: center;
}

.customer-card:hover {
  transform: scale(1.05);
  background-color: #4f4946;
}

.v-btn {
  border-radius: 12px;
}
</style>
