<template>
  <v-card class="location-card d-flex flex-column justify-center align-center"
          :class="{ 'selected-card': selectedLocation && selectedLocation.id === location.id }"
          @click="$emit('select-location', location)"
          max-width="350px"
          max-height="300px">
    <v-img
        src="https://image.cdn2.seaart.ai/2023-09-27/18327167403307013/e8828db7d6309f973216b94459976f2e4920a14c_high.webp"
        aspect-ratio="1.5"
        class="location-image">
    </v-img>
    <v-card-text class="text-center text-white font-weight-bold location-text">
      {{ location.name }}
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    selectedLocation: {
      type: Object,
      required: true,
    },
    location: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style scoped>
.location-card {
  cursor: pointer;
  background: #2a2a2a;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s, background-color 0.2s;
  border-radius: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  min-width: 500px;
}

.location-card:hover {
  transform: scale(1.05);
}

.selected-card {
  border: 3px solid #4db6ac;
}

.location-image {
  border-radius: 10px;
  width: 400px;
}

.location-text {
  padding: 8px 0;
}
</style>