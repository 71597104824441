<template>
  <v-card class="card-body mt-4">
    <v-card-text>
      <v-text-field v-model="localOrderSearch"
                    label="Search Orders"
                    prepend-icon="mdi-magnify"
                    class="mb-4"
                    outlined>
      </v-text-field>

      <v-data-table :headers="headers"
                    :items="filteredOrders"
                    item-value="id"
                    class="elevation-1 data-table"
                    hide-default-footer>
        <template v-slot:item.items="{ item }">
          <div class="mt-4">
            <v-text-field v-model="localItemSearch"
                          label="Search Items"
                          prepend-icon="mdi-magnify"
                          class="mb-2"
                          outlined>
            </v-text-field>
            <v-card v-for="product in filterItems(item.items)"
                    :key="product.id"
                    class="product-card"
                    outlined>
              <v-card-title class="text-h6 text-white">
                {{ product.name }}
              </v-card-title>
              <v-card-text class="text-white">
                <strong>Category:</strong> {{ product.category }}<br />
                <strong>Price:</strong> ${{ product.price }}<br />
                <strong>Description:</strong> {{ product.description }}<br />
                <strong>Quantity:</strong> {{ product.quantity }}
              </v-card-text>
            </v-card>
          </div>
        </template>
      </v-data-table>

    </v-card-text>
    <v-btn block
           color="red"
           class="mt-4 white--text"
           @click="$emit('delete-customer')">
      Delete Customer
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: "OrderTable",
  props: {
    orders: {
      type: Array,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    searchTerm: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      localOrderSearch: this.searchTerm,
      localItemSearch: ""
    };
  },
  computed: {
    filteredOrders() {
      return this.orders.filter(order =>
          order.id.toString().includes(this.localOrderSearch) ||
          order.created_at.includes(this.localOrderSearch)
      );
    },
  },
  methods: {
    filterItems(items) {
      return items.filter(item =>
          item.name.toLowerCase().includes(this.localItemSearch.toLowerCase()) ||
          item.description.toLowerCase().includes(this.localItemSearch.toLowerCase()) ||
          item.category.toLowerCase().includes(this.localItemSearch.toLowerCase())
      );
    }
  }
}
</script>

<style scoped>
.card-body {
  background-color: #6c6662;
  border-radius: 12px;
  padding: 20px;
}

.data-table {
  background-color: #4f4946;
  border-radius: 12px;
}

.product-card {
  background-color: #4f4d4c;
  border-radius: 12px;
  margin-bottom: 12px;
  padding: 16px;
  text-align: left;
  color: white;
}

.product-card .text-h6 {
  margin-bottom: 4px;
  font-weight: bold;
}
</style>
