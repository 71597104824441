<template>
  <v-app>
    <v-main>
      <v-container class="d-flex align-center justify-center fill-height" fluid>
        <v-row justify="center">
          <v-col cols="12"
                 sm="8"
                 md="6"
                 lg="4">
            <v-card class="pa-6 login-card">
              <v-form>
                <v-card-title class="headline text-center text-white">
                  Staff Login
                </v-card-title>
                <v-card-text>

                  <v-text-field label="Email"
                                v-model="email"
                                hide-details
                                color="primary"
                                solo
                                class="custom-input">
                  </v-text-field>
                  <v-text-field label="Password"
                                v-model="password"
                                type="password"
                                hide-details
                                color="primary"
                                solo
                                class="custom-input">
                  </v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-btn block @click="submitLogin" class="login-button">
                    Login
                  </v-btn>
                </v-card-actions>
                <v-card-actions>
                  <v-btn text block @click="forgotPassword" class="forgot-password">
                    Forgot Password?
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import axios from "axios";
export default {

  data() {
    return {
      email: '',
      password: '',
    };
  },
  computed: {
    ...mapGetters(['config']),
  },
  created() {

    this.fetchConfig();
  },
  methods: {
    errorLoginAlert() {
      this.$swal.fire({
        title: '<p class="text-white">Error</p>',
        html: '<strong class="text-white">The email or password field is blank.</strong>',
        confirmButtonText: 'Close',
        confirmButtonColor: "white",
        icon: 'error',
        background: '#212d48',
        padding: '2em',
        timer: 3500,
      })
    },
    ...mapActions(['fetchConfig']),
    async submitLogin() {
      if (this.email === "" || this.password === "") {
        this.errorLoginAlert();

      } else {
        try {
          const response = await axios.post('/user/login', {
            email: this.email,
            password: this.password,
          });
          const token = response.data.access_token;
          localStorage.setItem('token', token);
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          this.$router.push('/staff');
        } catch (error) {
          console.error('Staff login failed:', error);
        }
      }
    },
    forgotPassword() {
      this.$router.push('/forgot-password');
    }
  },
};
</script>
<style scoped>
.fill-height {
  min-height: 100vh;
  background: linear-gradient(to bottom right, #807975, #000c12);
}

.login-card {
  background-color: #807975;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5);
  padding: 30px;
}

.text-white {
  color: white !important;
}

.custom-input {
  background-color: #807975;
  border-radius: 8px;
  color: white;
}

.custom-input input {
  color: white !important;
  border: none;
}

.custom-input .v-input__control {
  border: none !important;
}

.custom-input .v-field__details {
  display: none;
}

.login-button {
  background-color: #4db6ac;
  color: white;
  font-weight: bold;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(79, 239, 234, 0.4);
}

.login-button:hover {
  background-color: #3e948c;
}

.forgot-password {
  color: #B0BEC5;
  text-transform: none;
  font-size: 14px;
}

.forgot-password:hover {
  color: #FFFFFF;
}

.v-card-title {
  font-size: 22px;
  font-weight: bold;
}

@media (max-width: 600px) {
  .v-card {
    padding: 16px;
  }

  .v-card-title {
    font-size: 18px;
  }
}
</style>
