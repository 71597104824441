<template>
  <div v-if="user && config && isAll">
    <v-navigation-drawer color="transparent"
                         expand-on-hover
                         style="border: 0px"
                         mobile-breakpoint="sm">
      <v-list>
        <v-list-item prepend-avatar="https://randomuser.me/api/portraits/men/82.jpg"
                     :subtitle="user.email"
                     :title="user.name"
                     style="color: white;">
        </v-list-item>
      </v-list>
      <v-divider class="mr-4 ml-4"
                 style="color: white;">
      </v-divider>
      <v-list density="compact"
              nav>
        <v-list-item prepend-icon="mdi-account-clock"
                     :to="'/staff/timeclock'"
                     :disabled="!isAll">
          <p class="nav-text">
            Time Clock
          </p>
        </v-list-item>
        <v-list-item prepend-icon="mdi-format-list-bulleted"
                     :to="'/staff/todo'"
                     :disabled="!isAll">
          <p class="nav-text">
            To-do
          </p>
        </v-list-item>
        <v-list-item prepend-icon="mdi-calendar-account"
                     :to="'/staff/schedule'"
                     :disabled="!isAll">
          <p class="nav-text">
            Schedule
          </p>
        </v-list-item>
        <v-list-item prepend-icon="mdi-information"
                     :to="'/staff/information'"
                     :disabled="!isAll">
          <p class="nav-text">
            Information
          </p>
        </v-list-item>
      </v-list>
      <v-divider class="mr-4 ml-4"
                 style="color: white;">
      </v-divider>
      <v-list density="compact"
              nav>
        <v-list-item prepend-icon="mdi-calendar-month"
                     :to="'/staff/appointments'"
                     :disabled="!isManagerAdmin">
          <p class="nav-text">
            Appointments
          </p>
        </v-list-item>

        <v-list-item prepend-icon="mdi-account-hard-hat"
                     :to="'/staff/employees'"
                     :disabled="!isManagerAdmin">
          <p class="nav-text">
            Employees
          </p>
        </v-list-item>
        <v-list-item prepend-icon="mdi-pen"
                     :to="'/staff/applications'"
                     :disabled="!isManagerAdmin">
          <p class="nav-text">
            Hiring
          </p>
        </v-list-item>
        <v-list-item prepend-icon="mdi-wardrobe"
                     :to="'/staff/inventory'"
                     :disabled="!isManagerAdmin">
          <p class="nav-text">
            Inventory
          </p>
        </v-list-item>
        <v-list-item prepend-icon="mdi-account-multiple"
                     :to="'/staff/customers'"
                     :disabled="!isManagerAdmin">
          <p class="nav-text">
            Customers & Orders
          </p>
        </v-list-item>
        <v-list-item prepend-icon="mdi-cart"
                     :to="'/staff/ordering'"
                     :disabled="!isManagerAdmin">
          <p class="nav-text">
            Online Ordering
          </p>
        </v-list-item>
        <v-list-item prepend-icon="mdi-chart-histogram"
                     :to="'/staff/analytics'"
                     :disabled="!isManagerAdmin">
          <p class="nav-text">
            Analytics
          </p>
        </v-list-item>
      </v-list>
      <v-divider class="mr-4 ml-4"
                 style="color: white;">
      </v-divider>
      <v-list density="compact"
              nav>
        <v-list-item prepend-icon="mdi-lock"
                     :to="'/staff/roles'"
                     :disabled="!isAdmin">
          <p class="nav-text">
            Roles
          </p>
        </v-list-item>
        <v-list-item prepend-icon="mdi-map-marker-multiple"
                     :to="'/staff/locations'"
                     :disabled="!isAdmin">
          <p class="nav-text">
            Locations
          </p>
        </v-list-item>
        <v-list-item prepend-icon="mdi-sitemap"
                     :to="'/staff/config'"
                     :disabled="!isAdmin">
          <p class="nav-text">
            Site Settings
          </p>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar style="background-color: transparent"
               elevation="0">
      <v-spacer>
      </v-spacer>
      <v-btn icon>
        <v-icon class="text-white">
          mdi-bell-outline
        </v-icon>
      </v-btn>
      <v-btn icon
             @click="dialog = true">
        <v-icon class="text-white">
          mdi-cog-outline
        </v-icon>
      </v-btn>
      <v-btn icon
             @click="logout">
        <v-icon class="text-white">
          mdi-logout
        </v-icon>
      </v-btn>
      <p class="title-text text-center mr-8 ml-4 mb-1">
        {{ config.appname }}
      </p>
    </v-app-bar>
    <StaffSettings v-model="dialog"
                   :user="user"/>
  </div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import StaffSettings from "./StaffSettings.vue";

export default {
  components: {
    StaffSettings,
  },
  data() {
    return {
      dialog: false,
      isMobile: false,
    };
  },
  created() {
    const user = this.$store.dispatch('fetchUser');
    if (user) {
      const ACCOUNT_ID = localStorage.getItem("account_id");
      this.$store.dispatch('fetchConfig', ACCOUNT_ID);
    }
  },
  computed: {
    ...mapGetters(['user', 'config']),
    isManagerAdmin() {
      return this.user.roles.some(role => role.name === 'Admin' || role.name === 'Manager');
    },
    isAdmin() {
      return this.user.roles.some(role => role.name === 'Admin');
    },
    isAll() {
      if (this.user.roles.some(role => role.name === 'All')) {
        return true;
      } else {
        this.$router.push('/staff/login')
        return false
      }
    }
  },
  methods: {
    ...mapActions(['fetchUser', 'fetchConfig']),
    logout() {
      this.$store.dispatch('logout');
    },
  },
};
</script>
<style scoped>
.nav-text {
  color: white;
  font-weight: bold;
  font-size: 13px;
}

.title-text {
  color: white;
  font-weight: bold;
  font-size: 28px;
}
</style>
