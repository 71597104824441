<template>
  <StaffPageWrapper :config="config">
    test
    <pre>{{ user }}</pre>

  </StaffPageWrapper>
</template>

<script>
import StaffPageWrapper from "@/views/staff/components/StaffPageWrapper.vue";
import {mapGetters, mapActions} from "vuex";
import axios from "axios";

export default {
  components: {
    StaffPageWrapper,
  },
  data() {
    return {
      inventories: [],
      locationName: '',
    };
  },
  created() {
    this.fetchConfig();
  },

  computed: {
    ...mapGetters(['config', 'user']),
  },
  methods: {
    ...mapActions(['fetchConfig', 'fetchUser']),

  },
};
</script>

<style scoped>
</style>
