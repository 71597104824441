<template>
  <StaffPageWrapper :config="config">
    <v-container fluid>
      <v-row>
        <v-col cols="12"
               md="6">
          <v-card style="background: #2a2a2a; border-radius: 12px;"
                  class="text-white">
            <v-card-title class="text-white">
              <strong>
                Edit Application Template
              </strong>
            </v-card-title>
            <v-card-text>
              <div v-for="template in applicationTemplates"
                   :key="template.id"
                   @click="selectTemplate(template)">
                <v-text-field label="Name"
                              v-model="template.name">
                </v-text-field>
                <v-text-field label="Description"
                              v-model="template.description">
                </v-text-field>
                <div v-for="(question, index) in template.questions"
                     :key="index"
                     class="d-flex align-center mb-3">
                  <v-text-field label="Question"
                                v-model="template.questions[index]"
                                class="flex-grow-1">
                  </v-text-field>
                  <v-icon color="red"
                          @click="removeQuestion(index)"
                          class="mb-4 ml-2">mdi-close-circle
                  </v-icon>
                </div>
                <v-btn color="teal lighten-2"
                       class="white--text mb-2"
                       @click="addQuestion">
                  Add Question
                </v-btn>
              </div>
            </v-card-text>
            <v-btn class="mb-2 ml-4"
                   color="#4db6ac"
                   @click="updateTemplate">
              Save
            </v-btn>
          </v-card>
        </v-col>
        <v-col cols="12"
               md="6">
          <v-card style="background-color: #2a2a2a; border-radius: 12px;">
            <v-card-title class="text-white">
              <strong>
                Application Submissions
              </strong>
            </v-card-title>
            <v-card-text>
              <v-row justify="center"
                     align="center"
                     class="mt-4">
                <v-col v-for="submission in applicationSubmissions"
                       :key="submission.id"
                       cols="auto">
                  <v-card class="submission-card d-flex flex-column align-center"
                          style="cursor: pointer; border-radius: 12px; padding: 16px; background: #2a2a2a;"
                          @click="goToSubmissionDetail(submission)">
                    <v-card-title class="text-center text-white d-flex align-center">
                      <v-icon left color="teal lighten-2"
                              class="mr-2">
                        mdi-clipboard-text
                      </v-icon>
                      <strong>
                        {{ submission.name }}
                      </strong>
                    </v-card-title>
                    <v-card-subtitle class="text-white">
                      {{ submission.email }}
                    </v-card-subtitle>
                    <v-btn icon color="teal lighten-2"
                           @click.stop="downloadSubmission(submission)">
                      <v-icon @click.stop="downloadSubmission(submission)">
                        mdi-download
                      </v-icon>
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </StaffPageWrapper>
</template>
<script>
import StaffPageWrapper from "@/views/staff/components/StaffPageWrapper.vue";
import {mapGetters, mapActions} from "vuex";
import axios from "axios";

export default {
  components: {
    StaffPageWrapper,
  },
  data() {
    return {
      applicationTemplates: [],
      applicationSubmissions: [],
      selectedTemplate: null,
      selectedSubmission: null,
    };
  },
  computed: {
    ...mapGetters(["config", "user"]),
  },
  mounted() {
    this.fetchUser();
  },
  created() {
    this.fetchConfig();
    this.fetchApplications();
  },
  methods: {
    ...mapActions(["fetchConfig", "fetchUser"]),
    async fetchApplications() {
      const token = localStorage.getItem("token");
      const account_id = localStorage.getItem("account_id");

      try {
        const templatesResponse = await axios.get("/application-templates", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            account_id: account_id,
          },
        });
        this.applicationTemplates = templatesResponse.data;
        if (this.applicationTemplates.length > 0) {
          this.selectedTemplate = this.applicationTemplates[0];
        }
      } catch (error) {
        console.error("Error fetching application templates:", error);
      }

      try {
        const submissionsResponse = await axios.get("/application-submissions", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            account_id: account_id,
          },
        });
        this.applicationSubmissions = submissionsResponse.data;
      } catch (error) {
        console.error("Error fetching application submissions:", error);
      }
    },
    selectTemplate(template) {
      this.selectedTemplate = template;
    },
    addQuestion() {
      if (!this.selectedTemplate) {
        alert("Please select a template first.");
        return;
      }
      if (!this.selectedTemplate.questions) {
        this.$set(this.selectedTemplate, "questions", []);
      }
      this.selectedTemplate.questions.push("");
    },
    removeQuestion(index) {
      if (this.selectedTemplate && this.selectedTemplate.questions) {
        this.selectedTemplate.questions.splice(index, 1);
      }
    },
    async updateTemplate() {
      const token = localStorage.getItem("token");

      try {
        await axios.put(`/application-templates/${this.selectedTemplate.id}`, this.selectedTemplate, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        alert("Template updated successfully!");
        this.fetchApplications();
      } catch (error) {
        console.error("Error updating template:", error);
      }
    },
    goToSubmissionDetail(submission) {
      this.$router.push({name: "SubmissionDetail", params: {id: submission.id}});
    },
    async downloadSubmission(submission) {
      try {
        const response = await axios.get(`/application-submissions/${submission.id}/download`, {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${submission.name}_application.pdf`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error("Error downloading submission:", error);
      }
    },
  },
};
</script>

<style scoped>
.text-white {
  color: white !important;
}

.submission-card {
  cursor: pointer;
  padding: 16px;
  border-radius: 10px;
  transition: transform 0.2s;
  background-color: #6c6662;
}

.submission-card:hover {
  transform: scale(1.05);
  background-color: #4f4946;
}
</style>
