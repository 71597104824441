<template>
  <v-app style="background-image: linear-gradient(to bottom right, #3a3a42, #573e2e);">
    <v-main>
      <StaffSideTopBar :config="config"/>
      <v-container class="fill-height no-padding"
                   fluid
                   style="background-color: transparent;">
        <v-card class="overlapping-card elevation-2 full-screen-card">
          <slot/>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import StaffSideTopBar from "@/views/staff/components/StaffSideTopBar.vue";

export default {
  name: 'FullPageWrapper',
  components: {
    StaffSideTopBar,
  },
  props: {
    config: {
      type: Object,
      required: true,
    }
  },
  methods: {
  },
}
</script>
<style scoped>
.fill-height {
  min-height: 100vh;
  background: #576a78;
  display: flex;
  flex-direction: column;
}

.no-padding {
  padding: 0 !important;
}

.full-screen-card {
  min-height: calc(100vh - 64px);
  min-width: 85vw;
  background-color: #1e1e1e;
  padding: 30px;
  border-radius: 20px;
  color: white;
  position: relative;
  left: 0;
  right: 0;
}
</style>
