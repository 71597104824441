<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import {mapGetters} from 'vuex';
import Loading from "@/views/Loading.vue";

export default {
  name: 'App',
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(['loading'])
  }
}
</script>
