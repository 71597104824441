import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import store from './store';
import Swal from 'sweetalert2';

const app = createApp(App);
axios.defaults.baseURL = 'https://swiftsolutions.app/api';
const ACCOUNT_ID = 1;
localStorage.setItem('account_id', ACCOUNT_ID);
axios.interceptors.request.use((config) => {
    if (!config.params) {
        config.params = {};
    }
    config.params.account_id = ACCOUNT_ID;

    return config;
}, (error) => {
    return Promise.reject(error);
});

export { ACCOUNT_ID }

app.config.globalProperties.$swal = Swal;
app.use(router);
app.use(store);
app.use(vuetify);

app.mount('#app');
