<template>
  <StaffPageWrapper :config="config">
    <v-card>
      <v-card-title>
        {{ form.name }}'s Appointment
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field v-model="form.name"
                        label="Name"
                        :disabled="!isEditing">
          </v-text-field>
          <v-text-field v-model="form.year_of_car"
                        label="Year of Car"
                        :disabled="!isEditing">
          </v-text-field>
          <v-text-field v-model="form.make_of_car"
                        label="Make of Car"
                        :disabled="!isEditing">
          </v-text-field>
          <v-text-field v-model="form.model_of_car"
                        label="Model of Car"
                        :disabled="!isEditing">
          </v-text-field>
          <v-select v-model="form.status"
                    :items="statusArray"
                    item-value="id"
                    item-title="name"
                    label="Status">
          </v-select>
          <v-select
              v-model="form.user_id"
              :items="userOptions"
              item-value="id"
              item-title="name"
              label="user"
              :disabled="!isEditing">
          </v-select>
          <v-divider class="my-4">
          </v-divider>
          <h2 class="text-h6">
            Add Video or Image Diagnosis
          </h2>
          <v-file-input v-model="form.media"
                        label="Upload Video or Image"
                        accept="image/*, video/*"
                        prepend-icon="mdi-camera"
                        :disabled="!isEditing">
          </v-file-input>
          <v-btn @click="saveAppointment">
            Save
          </v-btn>
          <v-btn @click="startEditing">
            Edit
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </StaffPageWrapper>
</template>
<script>
import StaffPageWrapper from "@/views/staff/components/StaffPageWrapper.vue";
import {mapGetters, mapActions} from "vuex";
import axios from "axios";

export default {
  components: {
    StaffPageWrapper,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
      form: {
        name: "",
        year_of_car: "",
        make_of_car: "",
        model_of_car: "",
        status: "",
        media: null,
        user_id: "",
      },
      activityStatuses: {
        active: {id: 1, name: 'Active'},
        inactive: {id: 2, name: 'Inactive'},
        paid: {id: 3, name: 'Paid'},
      },
      userOptions: [],
    };
  },
  computed: {
    ...mapGetters(["config", "user"]),
    statusArray() {
      return Object.values(this.activityStatuses);
    },
  },
  mounted() {
    this.fetchAppointment();
    this.fetchUsers();
  },
  methods: {
    ...mapActions(["fetchConfig", "fetchUser"]),
    async fetchAppointment() {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`/appointments/${this.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const appointment = response.data;
        this.form = {
          name: appointment.name,
          year_of_car: appointment.year_of_car,
          make_of_car: appointment.make_of_car,
          model_of_car: appointment.model_of_car,
          status: appointment.status,
          user_id: appointment.user_id || "",
          media: null,
        };
      } catch (error) {
        console.log(error);
      }
    },
    async fetchUsers() {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/users", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.userOptions = response.data;
      } catch (error) {
        console.error("Error fetching users", error);
      }
    },
    async saveAppointment() {
      try {
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append('name', this.form.name);
        formData.append('year_of_car', this.form.year_of_car);
        formData.append('make_of_car', this.form.make_of_car);
        formData.append('model_of_car', this.form.model_of_car);
        formData.append('status', this.form.status);
        formData.append('user_id', this.form.user_id);
        if (this.form.media) {
          formData.append('media', this.form.media);
        }
        const response = await axios.put(`/appointments/${this.id}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        this.$router.push("/staff/appointments");
      } catch (error) {
        console.error("Error saving appointment", error);
      }
    },
    startEditing() {
      this.isEditing = !this.isEditing;
    },

  },
};
</script>
