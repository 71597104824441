<template>
  <StaffPageWrapper :config="config">
    <v-container fluid>
      <v-card
          style="background: #2a2a2a; border-radius: 12px; padding: 20px; text-align: center;">
        <v-card-title class="text-white">
          <strong>
            Manage Employees
          </strong>
        </v-card-title>
        <v-card-text class="text-white">
          Manage Employees, Managers, Locations, Access & More.
        </v-card-text>
      </v-card>
      <v-card style="background-color: #2a2a2a; border-radius: 12px; margin-top: 20px;">
        <v-card-text>
          <v-text-field v-model="employeeSearch"
                        label="Search Employees"
                        prepend-icon="mdi-magnify"
                        class="mb-4"
                        outlined>
          </v-text-field>
        </v-card-text>
      </v-card>
      <v-row justify="center"
             align="center"
             class="mt-4">
        <v-col v-for="employee in filteredEmployees"
               :key="employee.id"
               cols="auto">
          <v-card class="employee-card d-flex flex-column align-center"
                  style="cursor: pointer; border-radius: 12px; padding: 16px; background: #2a2a2a;"
                  @click="selectEmployee(employee)">
            <v-card-text class="text-center text-white d-flex align-center">
              <v-icon left
                      color="teal lighten-2"
                      class="mr-2">
                mdi-account-circle
              </v-icon>
              <strong>
                {{ employee.name }}
              </strong>
            </v-card-text>
            <v-card-subtitle class="text-white">
              {{ employee.email }}
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
      <v-card v-if="selectedEmployee"
              style="background-color: #4f4946; border-radius: 12px; margin-top: 20px;">
        <v-card-title class="text-white">
          Edit User: {{ selectedEmployee.name }}
        </v-card-title>
        <v-card-text>
          <v-form ref="editEmployeeForm">
            <v-row>
              <v-col cols="12"
                     md="6">
                <v-text-field v-model="selectedEmployee.name"
                              label="Name"
                              outlined
                              class="form-item text-white">
                </v-text-field>
              </v-col>
              <v-col cols="12"
                     md="6">
                <v-text-field v-model="selectedEmployee.email"
                              label="Email"
                              outlined
                              class="form-item text-white">
                </v-text-field>
              </v-col>
              <v-col cols="12"
                     md="6">
                <v-select :items="locations"
                          v-model="selectedEmployee.location.name"
                          label="Location"
                          item-value="id"
                          item-title="name"
                          outlined
                          class="form-item text-white"
                          :loading="isLoadingLocations"
                          :disabled="!locations.length"
                          hint="Warning: be cautious before changing an employee's working location."
                          persistent-hint>
                </v-select>
              </v-col>
            </v-row>
            <v-row class="d-flex align-center">
              <v-col cols="6"
                     class="d-flex justify-start">
                <v-btn color="#4db6ac"
                       @click="updateEmployee"
                       class="white--text mr-2">
                  Save Changes
                </v-btn>
                <v-btn color="grey"
                       @click="selectedEmployee = null"
                       class="white--text">
                  Cancel
                </v-btn>
              </v-col>
              <v-col cols="6"
                     class="d-flex justify-end">
                <v-btn color="blue"
                       @click="sendPasswordResetEmail(selectedEmployee.id)"
                       class="white--text mr-2">
                  Send Password Reset Email
                </v-btn>
                <v-btn color="red"
                       @click="deleteEmployee(selectedEmployee.id)"
                       class="white--text">
                  Delete User
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-btn block
             color="#4db6ac"
             class="mt-4 white--text"
             @click="openNewEmployeeModal">
        Add New Employee
      </v-btn>
      <v-dialog v-model="showNewEmployeeModal"
                max-width="600px">
        <v-card>
          <v-card-title
              class="text-h5">
            Add New Employee
          </v-card-title>
          <v-card-text>
            <v-form ref="newEmployeeForm">
              <v-text-field v-model="newEmployee.name"
                            label="Name"
                            outlined>
              </v-text-field>
              <v-text-field v-model="newEmployee.email"
                            label="Email"
                            outlined>
              </v-text-field>
              <v-text-field v-model="newEmployee.password"
                            label="Password"
                            outlined
                            type="password">
              </v-text-field>
              <v-select :items="locations"
                        v-model="newEmployee.location_id"
                        item-text="name"
                        item-value="id"
                        label="Location"
                        outlined
                        :loading="isLoadingLocations"
                        :disabled="!locations.length">
              </v-select>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer>
            </v-spacer>
            <v-btn color="grey"
                   text
                   @click="showNewEmployeeModal = false">
              Cancel
            </v-btn>
            <v-btn color="#4db6ac"
                   text
                   @click="createNewEmployee">
              Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </StaffPageWrapper>
</template>
<script>
import StaffPageWrapper from "@/views/staff/components/StaffPageWrapper.vue";
import {mapGetters, mapActions} from "vuex";
import axios from "axios";

export default {
  components: {
    StaffPageWrapper,
  },
  data() {
    return {
      employees: [],
      selectedEmployee: null,
      employeeSearch: "",
      showNewEmployeeModal: false,
      newEmployee: {
        name: "",
        email: "",
        password: "",
        location_id: null,
      },
      locations: [],
      isLoadingLocations: false,
    };
  },
  computed: {
    ...mapGetters(['config', 'user']),
    filteredEmployees() {
      return this.employees.filter(employee =>
          employee.name.toLowerCase().includes(this.employeeSearch.toLowerCase())
      );
    },
  },
  created() {
    this.fetchConfig();
    this.fetchEmployees();
    this.fetchLocations();
  },
  methods: {
    ...mapActions(['fetchConfig', 'fetchUser']),
    async fetchEmployees() {
      const token = localStorage.getItem('token');
      const account_id = localStorage.getItem('account_id');

      try {
        const response = await axios.get('/users', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            account_id: account_id,
          },
        });
        this.employees = response.data;
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    },
    async fetchLocations() {
      this.isLoadingLocations = true; // Start loading
      const token = localStorage.getItem('token');
      const account_id = localStorage.getItem('account_id');

      try {
        const response = await axios.get('/locations', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            account_id: account_id,
          },
        });
        if (Array.isArray(response.data)) {
          this.locations = response.data; // Ensure locations are an array
        } else {
          console.error('Unexpected response format for locations:', response.data);
        }
      } catch (error) {
        console.error('Error fetching locations:', error);
      } finally {
        this.isLoadingLocations = false; // Stop loading
      }
    },
    selectEmployee(employee) {
      this.selectedEmployee = {
        ...employee,
        location_id: employee.location ? employee.location.id : null,
      };
    },
    async updateEmployee() {
      const token = localStorage.getItem('token');
      try {
        await axios.put(`/users/${this.selectedEmployee.id}`, this.selectedEmployee, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        alert('Employee updated successfully');
        this.fetchEmployees();
        this.selectedEmployee = null;
      } catch (error) {
        console.error('Error updating employee:', error);
      }
    },
    async deleteEmployee(employeeId) {
      const token = localStorage.getItem('token');
      try {
        await axios.delete(`/users/${employeeId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        alert('Employee deleted successfully');
        this.fetchEmployees();
      } catch (error) {
        console.error('Error deleting employee:', error);
      }
    },
    async sendPasswordResetEmail(userId) {
      try {
        await axios.post('/password-reset-request', {user_id: userId});
        alert('Password reset email sent.');
      } catch (error) {
        console.error('Error sending password reset email:', error);
      }
    },
    openNewEmployeeModal() {
      this.showNewEmployeeModal = true;
    },
    async createNewEmployee() {
      const token = localStorage.getItem('token');
      try {
        await axios.post('/users', this.newEmployee, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        alert('New employee added successfully');
        this.fetchEmployees();
        this.showNewEmployeeModal = false;
      } catch (error) {
        console.error('Error adding new employee:', error);
      }
    },
  },
};
</script>

<style scoped>
.employee-card {
  cursor: pointer;
  padding: 16px;
  border-radius: 10px;
  transition: transform 0.2s;
  background-color: #6c6662;
}

.employee-card:hover {
  transform: scale(1.05);
  background-color: #4f4946;
}

.form-item {
  margin-bottom: 16px;
}
</style>
