<template>
  <StaffPageWrapper :config="config">
    test
  </StaffPageWrapper>
</template>
<script>
import StaffPageWrapper from "@/views/staff/components/StaffPageWrapper.vue";
import {mapGetters, mapActions} from "vuex";

export default {
  components: {
    StaffPageWrapper,
  },

  computed: {
    ...mapGetters(['config', 'user']),
  },
  mounted() {
    this.fetchUser();
  },
  created() {
    this.fetchConfig();
  },
  methods: {
    ...mapActions(['fetchConfig', 'fetchUser']),
  },
}
</script>
<style scoped>

</style>