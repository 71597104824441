import Customers from "@/views/management/customers/Customers.vue";
import Analytics from "@/views/management/analytics/Analytics.vue";
import Ordering from "@/views/management/ordering/Ordering.vue";
import Employees from "@/views/management/employees/Employees.vue";
import Applications from "@/views/management/applications/Applications.vue";
import Appointments from "@/views/management/appointments/Appointments.vue";
import EditAppointment from "@/views/management/appointments/EditAppointment.vue";
import Inventory from "@/views/management/inventory/Inventory.vue";

const managementRoutes = [
    // Customers
    {
        path: "/staff/customers",
        name: "staff-customers",
        component: Customers,
    },
    // Employees
    {
        path: "/staff/employees",
        name: "staff-employees",
        component: Employees,
    },
    // Ordering
    {
        path: "/staff/ordering",
        name: "staff-ordering",
        component: Ordering,
    },
    // Analytics
    {
        path: "/staff/analytics",
        name: "staff-analytics",
        component: Analytics,
    },
    // Applications
    {
        path: "/staff/applications",
        name: "staff-applications",
        component: Applications,
    },
    // Inventory
    {
        path: '/staff/inventory',
        name: 'staff-inventory',
        component: Inventory,
    },
    // Appointments
    {
        path: "/staff/appointments",
        name: "staff-appointments",
        component: Appointments,
    },
    // Edit Appointment
    {
        path: "/staff/appointments/edit/:id",
        name: "staff-appointments-edit",
        component: EditAppointment,
        props: true,
    },
]
export default managementRoutes;