<template>
<StaffPageWrapper :config="config">
          <v-row>
            <v-col cols="12">
              <v-card-title class="text-white text-center">
                <strong>
                  Tasks & Duties
                </strong>
              </v-card-title>
              <v-card style="background-color: #2b3655;"
                      elevation="0">
                <v-tabs v-model="tab">
                  <v-tab class="custom-tab"
                         value="one">
                    <p class="text-white">
                      Active Tasks
                    </p>
                  </v-tab>
                  <v-tab class="custom-tab"
                         value="two">
                    <p class="text-white">
                      Inactive Tasks
                    </p>
                  </v-tab>
                </v-tabs>
                <v-tabs-window v-model="tab">
                  <v-tabs-window-item value="one">
                    <v-list dense
                            style="background-color: #2b3655; elevation: 0">
                      <div v-for="task in activeTasks"
                           :key="task.id">
                        <v-list-item class="task-item">
                          <div class="task-info">
                            <p class="task-name">
                              {{ task.name }}
                            </p>
                            <p class="task-desc">
                              {{ task.description }}
                            </p>
                          </div>
                          <v-btn icon
                                 @click="toggleTaskStatus(task)">
                            <v-icon class="task-complete-btn">
                              mdi-check-circle-outline
                            </v-icon>
                          </v-btn>
                        </v-list-item>
                        <v-divider>
                        </v-divider>
                      </div>
                    </v-list>
                  </v-tabs-window-item>
                  <v-tabs-window-item value="two">
                    <v-list dense
                            style="background-color: #2b3655; elevation: 0">
                      <div v-for="task in inactiveTasks"
                           :key="task.id">
                        <v-list-item class="task-item">
                          <div class="task-info">
                            <p class="task-name">
                              {{ task.name }}
                            </p>
                            <p class="task-desc">
                              {{ task.description }}
                            </p>
                          </div>
                          <v-icon @click="toggleTaskStatus"
                                  class="task-incomplete-btn">
                            mdi-circle-outline
                          </v-icon>
                        </v-list-item>
                        <v-divider>
                        </v-divider>
                      </div>
                    </v-list>
                  </v-tabs-window-item>
                </v-tabs-window>
              </v-card>
            </v-col>
          </v-row>
</StaffPageWrapper>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { ACCOUNT_ID } from "@/main";
import axios from "axios";
import StaffPageWrapper from "@/views/staff/components/StaffPageWrapper.vue";

export default {
  components: {
    StaffPageWrapper,
  },
  data() {
    return {
      tab: 'one',
      user: [],
      tasks: [],
    };
  },
  computed: {
    ...mapGetters(["config"]),
    activeTasks() {
      return this.tasks.filter((task) => task.active);
    },
    inactiveTasks() {
      return this.tasks.filter((task) => !task.active);
    },
  },
  created() {
    this.fetchUser();
    this.fetchConfig();
    this.fetchTasks();
  },
  methods: {
    ...mapActions(["fetchConfig"]),

    async fetchUser() {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get("/user", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.user = response.data;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchTasks() {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`/tasks?account_id=${ACCOUNT_ID}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.tasks = response.data.tasks;
      } catch (error) {
        console.error("Failed to fetch tasks", error);
      }
    },
    async toggleTaskStatus(task) {
      const token = localStorage.getItem("token");
      try {
        await axios.put(`/tasks/${task.id}`, { active: !task.active }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.fetchTasks();
      } catch (error) {
        console.error("Failed to update task", error);
      }
    },
  },
};
</script>
<style scoped>
.fill-height {
  min-height: 100vh;
  background: #212d48;
  display: flex;
  flex-direction: column;
}
.no-padding {
  padding: 0 !important;
}

.full-screen-card {
  min-height: calc(100vh - 64px);
  min-width: 85vw;
  background-color: #2b3655;
  padding: 30px;
  border-radius: 20px;
  color: white;
  position: relative;
  left: 0;
  right: 0;
}
.task-item {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  background-color: transparent;
  margin-bottom: 10px;
  elevation: 0 !important;
}

.task-info {
  flex-grow: 1;
}

.task-name {
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.task-desc {
  font-size: 14px;
  margin-top: 5px;
  color: #b0bec5;
}
.v-btn {
  background-color: transparent !important;
}
.task-complete-btn {
  color: green !important;
}
.task-incomplete-btn {
  color: red !important;
}
.v-divider {
  background-color: white;
}
</style>