import {createStore} from 'vuex';
import axios from 'axios';
import router from "@/router";

const store = createStore({
    state: {
        isLoggedIn: false,
        user: null,
        config: {},
        loading: false,
    },
    mutations: {
        SET_LOGGED_IN(state, status) {
            state.isLoggedIn = status;
        },
        SET_USER(state, user) {
            state.user = user;
            if (user) {
                localStorage.setItem('account_id', user.account_id);
            } else {
                localStorage.removeItem('account_id');
            }
        },
        SET_CONFIG(state, config) {
            state.config = config;
        },
        SET_LOADING(state, status) {
            state.loading = status;
        },
    },
    actions: {
        async fetchUser({commit}) {
            commit('SET_LOADING', true);
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    commit('SET_USER', null);
                    commit('SET_LOGGED_IN', false);
                    console.log('Token not found, redirecting to login.');
                    router.push('/staff/login');
                    return null;
                }
                const response = await axios.get('/user', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const user = response.data;
                commit('SET_USER', user);
                commit('SET_LOGGED_IN', true);
                return user;
            } catch (error) {
                console.error("Error fetching user:", error);
                commit('SET_USER', null);
                commit('SET_LOGGED_IN', false);
                localStorage.removeItem('token');
                router.push('/staff/login');
                return null;
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async fetchConfig({commit}, ACCOUNT_ID) {
            commit('SET_LOADING', true);
            try {
                if (!ACCOUNT_ID) return null;
                const response = await axios.get('/config', {
                    params: {
                        account_id: ACCOUNT_ID,
                    },
                });
                commit('SET_CONFIG', response.data);
                return response.data;
            } catch (error) {
                console.error("Error fetching config:", error);
                return null;
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async logout({commit}) {
            commit('SET_LOADING', true);
            localStorage.removeItem('token');
            commit('SET_LOGGED_IN', false);
            commit('SET_USER', null);
            router.push('/staff/login');
            commit('SET_LOADING', false);
        },
    },
    getters: {
        isLoggedIn: (state) => state.isLoggedIn,
        user: (state) => state.user,
        config: (state) => state.config,
        loading: (state) => state.loading,
    },
});

export default store;
