<template>
  <StaffPageWrapper :config="config">
    <v-row>
      <v-col cols="12">
        <v-card-title class="text-white">
          Staff Dashboard
        </v-card-title>
        <v-card-text>
          <p>
            This is the content area for the staff side.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary">
            Action 1
          </v-btn>
          <v-btn color="primary">
            Action 2
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </StaffPageWrapper>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import StaffSideTopBar from "@/views/staff/components/StaffSideTopBar.vue";
import StaffPageWrapper from "@/views/staff/components/StaffPageWrapper.vue";

export default {
  components: {
    StaffSideTopBar,
    StaffPageWrapper,
  },
  data() {
    return {
      user: [],
    }
  },
  created() {
    const user = this.$store.dispatch('fetchUser');
    if (user) {
      const ACCOUNT_ID = localStorage.getItem("account_id");
      this.$store.dispatch('fetchConfig', ACCOUNT_ID);
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    ...mapActions(['fetchConfig']),
  },
}
</script>
