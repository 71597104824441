<template>
  <v-dialog v-model="dialog"
            max-width="500px">
    <v-card class="pa-4"
            color="primary"
            :style="{ borderRadius: '10px' }">
      <v-card-title :class="colors.textPrimary"
                    class="text-h6"
                    style="font-weight: bold;">
        Edit Profile
      </v-card-title>
      <v-card-text>
        <v-form ref="form"
                v-model="valid"
                lazy-validation>
          <v-text-field v-model="profile.name"
                        label="Name"
                        prepend-icon="mdi-account"
                        dense
                        outlined
                        :rules="nameRules"
                        color="primary"/>
          <v-text-field v-model="profile.email"
                        label="Email"
                        prepend-icon="mdi-email"
                        dense
                        outlined
                        :rules="emailRules"
                        color="primary"/>
          <v-text-field v-model="profile.password"
                        label="Password"
                        prepend-icon="mdi-lock"
                        type="password"
                        dense
                        outlined
                        :rules="passwordRules"
                        color="primary"/>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :color="colors.accent"
               dark
               @click="saveProfile"
               :disabled="!valid"
               style="border-radius: 10px; font-weight: bold;">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => ({name: '', email: ''}),
    },
  },
  data() {
    return {
      dialog: true,
      valid: false,
      profile: {
        name: this.user?.name || '',
        email: this.user?.email || '',
        password: '',
      },
      colors: {
        primary: 'primary',
        secondary: 'secondary',
        accent: 'accent',
        background: '#212d48',
        inputBackground: '#1a2a4d',
        textPrimary: 'white',
      },
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length >= 3) || 'Name must be at least 3 characters',
      ],
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password must be at least 6 characters',
      ],
    };
  },
  watch: {
    user: {
      immediate: true,
      handler(newUser) {
        if (newUser) {
          this.profile.name = newUser.name || '';
          this.profile.email = newUser.email || '';
        }
      },
    },
  },
  methods: {
    saveProfile() {
      const updatedProfile = {
        name: this.profile.name,
        email: this.profile.email,
        password: this.profile.password,
      };

      console.log("Updated Profile:", updatedProfile);
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
.v-input__control .v-input__slot {
  color: white !important;
}

.v-label {
  color: white !important;
}
</style>
