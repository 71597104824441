import {createRouter, createWebHistory} from 'vue-router'
import managementRoutes from "@/router/managementRoutes";
import staffRoutes from "@/router/staffRoutes";
import adminRoutes from "@/router/adminRoutes";

const routes = [
    ...managementRoutes,
    ...staffRoutes,
    ...adminRoutes,
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
