<template>
  <StaffPageWrapper :config="config">
    <v-card-title class="text-white">
      Application Configuration
    </v-card-title>
    <v-card-subtitle class="subtitle text-grey text-bold">
      Warning: Changing the configuration will affect how the site and application appear to customers. Proceed with caution.
    </v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="12"
               md="6">
          <v-text-field v-model="form.appname"
                        label="App Name"
                        outlined
                        dense
                        class="form-item"
                        prepend-inner-icon="mdi-application"/>
        </v-col>
        <v-col cols="12"
               md="6">
          <v-text-field v-model="form.description"
                        label="Description"
                        outlined dense class="form-item"
                        prepend-inner-icon="mdi-information-outline"/>
        </v-col>
        <v-col cols="12">
          <v-textarea v-model="form.extended_description"
                      label="Extended Description"
                      outlined
                      rows="4"
                      dense
                      class="form-item"
                      prepend-inner-icon="mdi-text"/>
        </v-col>
        <v-col cols="12"
               md="6">
          <v-text-field v-model="form.company_url"
                        label="Company URL"
                        outlined
                        dense
                        class="form-item"
                        prepend-inner-icon="mdi-web"/>
        </v-col>
        <v-col cols="12"
               md="6">
          <v-text-field v-model="form.twitter_url"
                        label="Twitter URL"
                        outlined
                        dense
                        class="form-item"
                        prepend-inner-icon="mdi-twitter"/>
        </v-col>
        <v-col cols="12"
               md="6">
          <v-text-field v-model="form.instagram_url"
                        label="Instagram URL"
                        outlined
                        dense
                        class="form-item"
                        prepend-inner-icon="mdi-instagram"/>
        </v-col>
        <v-col cols="12"
               md="6">
          <v-file-input v-model="form.logo_image"
                        label="Logo Image"
                        accept="image/*"
                        outlined
                        dense
                        class="form-item"
                        prepend-inner-icon="mdi-image"/>
        </v-col>
        <v-col cols="12"
               md="12">
          <v-file-input v-model="form.banner_image"
                        label="Banner Image"
                        accept="image/*"
                        outlined
                        dense
                        class="form-item"
                        prepend-inner-icon="mdi-image"/>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <v-card class="color-picker-card elevation-1">
            <v-row justify="center"
                   align="center">
              <v-col cols="3">
                <v-color-picker v-model="form.color"
                                label="Primary Color"
                                dark
                                class="color-picker"/>
              </v-col>
              <v-col cols="3">
                <v-color-picker v-model="form.secondary_color"
                                label="Secondary Color"
                                dark
                                class="color-picker"/>
              </v-col>
              <v-col cols="3">
                <v-color-picker v-model="form.accent_color"
                                label="Accent Color"
                                style="background-color: transparent;" rounded="12"/>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="end">
        <v-btn @click="confirmUpdate"
               class="mr-4" :style="{ backgroundColor: accentColor, color: 'white' }"
               elevation="2"
               prepend-icon="mdi-content-save">
          Update
        </v-btn>
      </v-row>
    </v-card-text>
  </StaffPageWrapper>
</template>
<script>
import axios from "axios";
import StaffPageWrapper from "@/views/staff/components/StaffPageWrapper.vue";
import {mapGetters, mapActions} from "vuex";
export default {
  components: {
    StaffPageWrapper,
  },
  data() {
    return {
      user: {},
      form: {
        appname: '',
        description: '',
        extended_description: '',
        company_url: '',
        twitter_url: '',
        instagram_url: '',
        color: '',
        secondary_color: '',
        accent_color: '',
        banner_image: null,
        logo_image: null,
      },
    };
  },
  computed: {
    ...mapGetters(["config"]),
    accentColor() {
      return '#4db6ac';
    }
  },
  created() {
    this.fetchConfig();
  },
  watch: {
    config: {
      immediate: true,
      handler(newConfig) {
        if (newConfig && Object.keys(newConfig).length > 0) {
          this.form = {
            appname: newConfig.appname || '',
            description: newConfig.description || '',
            extended_description: newConfig.extended_description || '',
            company_url: newConfig.company_url || '',
            twitter_url: newConfig.twitter_url || '',
            instagram_url: newConfig.instagram_url || '',
            color: newConfig.color || '',
            secondary_color: newConfig.secondary_color || '',
            accent_color: newConfig.accent_color || '',
            banner_image: null,
            logo_image: null,
          };
        }
      },
    },
  },
  methods: {
    ...mapActions(["fetchConfig"]),
    async confirmUpdate() {
      if (confirm("Are you sure you want to make changes? This will affect the public site.")) {
        await this.updateConfig();
      }
    },
    async updateConfig() {
      const token = localStorage.getItem('token');
      try {
        const formData = new FormData();
        formData.append('appname', this.form.appname);
        formData.append('description', this.form.description);
        formData.append('extended_description', this.form.extended_description);
        formData.append('company_url', this.form.company_url);
        formData.append('twitter_url', this.form.twitter_url);
        formData.append('instagram_url', this.form.instagram_url);
        formData.append('color', this.form.color);
        formData.append('secondary_color', this.form.secondary_color);
        formData.append('accent_color', this.form.accent_color);
        if (this.form.banner_image) {
          formData.append('banner_image', this.form.banner_image);
        }
        if (this.form.logo_image) {
          formData.append('logo_image', this.form.logo_image);
        }
        const response = await axios.put('/config', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('Configuration updated successfully', response.data);
      } catch (error) {
        console.error('Error updating configuration', error);
      }
    },
  },
};
</script>

<style scoped>
.text-white {
  color: #fff !important;
  font-size: 24px;
  font-weight: bold;
}

.subtitle {
  font-size: 16px;
  margin-bottom: 20px;
  color: #e57373;
}

.form-item {
  background-color: #2a2a2a;
  border-radius: 10px;
  color: white;
}

.color-picker-card {
  background-color: #2a2a2a !important;
  border-radius: 10px;
  padding: 20px;
}

.color-picker {
  background-color: transparent !important;
  padding: 10px;
  color: white;
}
</style>
