<template>
  <StaffPageWrapper :config="config">
    <h1 class="mb-2">
      Appointments
    </h1>
    <v-card v-if="appointments.length === 0">
      <v-card-title class="text-center"
                    bg-color="primary">
        There are no appointments to view.
      </v-card-title>
    </v-card>
    <v-card v-else>
      <v-card-title class="text-h5 text-center"
                    style="background-color: #3f3f3f">
        <v-row>
          <v-col cols="12"
                 sm="6">
            <v-text-field v-model="search"
                          label="Search appointments"
                          prepend-inner-icon="mdi-magnify"
                          dense
                          outlined
                          class="mb-2 mt-2">
            </v-text-field>
          </v-col>
          <v-col cols="12"
                 sm="6">
            <v-select v-model="statusFilter"
                      :items="statusOptions"
                      label="Filter by Status"
                      dense
                      outlined
                      class="mb-2 mt-2"
                      clearable>
            </v-select>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row>
        <v-card-actions class="mb-0">
          <v-btn @click="refresh"
                 small
                 icon="mdi-refresh"
                 color="accent">
          </v-btn>
        </v-card-actions>
      </v-row>
      <v-row>
        <v-col cols="12"
               sm="4"
               v-for="appointment in filteredAppointments"
               :key="appointment.id"
               class="mb-3">
          <v-card class="ml-4 mr-4 mt-10">
            <v-card-title style="font-weight: bold; font-size: x-large;">
              {{ appointment.name }}
            </v-card-title>
            <v-card-text>
              <div style="margin-top: 5px;">
                {{ appointment.year_of_car }} {{ appointment.make_of_car }} {{ appointment.model_of_car }}
              </div>
              <div style="margin-top: 5px;">
                {{ formatDate(appointment.start_date) }} - {{ formatTime(appointment.start_time) }}
              </div>
              <div v-if="appointment.user_id">
                {{ appointment.user }}
              </div>
              <v-chip :color="getStatusColor(appointment.status)"
                      dark
                      class="ml-0 mt-2">
                {{ appointment.status }}
              </v-chip>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="viewAppointment(appointment)"
                     block
                     color="accent">
                View Appointment
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </StaffPageWrapper>
</template>
<script>
import StaffPageWrapper from "@/views/staff/components/StaffPageWrapper.vue";
import {mapGetters, mapActions} from "vuex";
import axios from "axios";

export default {
  components: {
    StaffPageWrapper,
  },
  data() {
    return {
      appointments: [],
      selectedAppointment: null,
      search: "",
      statusFilter: "",
      dateFilter: "",
      dateMenu: false,
      statusOptions: ["Active", "Inactive", "Paid"],
    };
  },
  computed: {
    ...mapGetters(["config", "user"]),
    filteredAppointments() {
      const searchTerm = this.search.toLowerCase();

      return this.appointments.filter(appointment => {
        const matchesSearch = appointment.name.toLowerCase().includes(searchTerm) ||
            appointment.make_of_car.toLowerCase().includes(searchTerm) ||
            appointment.model_of_car.toLowerCase().includes(searchTerm) ||
            this.formatDate(appointment.start_date).toLowerCase().includes(searchTerm);

        const matchesStatus = this.statusFilter ? appointment.status === this.statusFilter : true;
        const matchesDate = this.dateFilter ? this.formatDate(appointment.start_date) === this.formatDate(this.dateFilter) : true;

        return matchesSearch && matchesStatus && matchesDate;
      });
    },
  },
  mounted() {
    this.fetchUser();
  },
  created() {
    this.fetchConfig();
    this.fetchAppointments();
  },
  methods: {
    ...mapActions(["fetchConfig", "fetchUser"]),
    async fetchAppointments() {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/appointments/all", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.appointments = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    formatDate(date) {
      const options = {year: "numeric", month: "long", day: "numeric"};
      return new Date(date).toLocaleDateString(undefined, options);
    },
    formatTime(time) {
      const [hours, minutes] = time.split(":");
      let amPm = hours >= 12 ? "PM" : "AM";
      let displayHour = hours > 12 ? hours - 12 : hours == 0 ? 12 : hours;
      return `${displayHour}:${minutes} ${amPm}`;
    },
    getStatusColor(status) {
      switch (status) {
        case "Active":
          return "green";
        case "Inactive":
          return "grey";
        case "Paid":
          return "blue";
        default:
          return "grey";
      }
    },
    viewAppointment(appointment) {
      this.$router.push({
        name: "staff-appointments-edit",
        params: {id: appointment.id},
      });
    },
    refresh() {
      this.fetchAppointments();
    },
  },
};
</script>
<style scoped>
.v-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-list-item-content {
  flex-grow: 1;
}

.v-chip {
  margin-left: 10px;
}
</style>
