<template>
  <StaffPageWrapper :config="config">
    <v-container fluid>
      <v-card class="info-card mb-4"
              color="primary">
        <v-card-title class="text-white">
          <strong>
            Information & Instructions
          </strong>
        </v-card-title>
        <v-card-text class="text-white">
          Welcome to the Information & Help Page. Here are some helpful guides to get you started using the
          SwiftSolutions app. If you need any help
          please contact your manager through the button below.
        </v-card-text>
      </v-card>
      <v-row>
        <v-col cols="12"
               sm="6"
               class="d-flex">
          <v-card class="info-section-card mb-4 flex-grow-1"
                  color="primary">
            <v-card-title class="text-white text-center">
              <strong>
                Creating a Reservation
              </strong>
            </v-card-title>
            <v-card-text class="text-white text-center">
              <ul class="text-white text-center">
                <li>
                  Go to the "Reservations" page from the sidebar menu.
                </li>
                <li>
                  Click the "Create Reservation" button.
                </li>
                <li>
                  Fill in the customer details, date, and time of the reservation.
                </li>
                <li>
                  Select the table or room and any special requests.
                </li>
                <li>
                  Click "Save" to confirm the reservation.
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12"
               sm="6"
               class="d-flex">
          <v-card class="info-section-card mb-4 flex-grow-1"
                  color="primary">
            <v-card-title class="text-white text-center">
              <strong>
                Managing the Time Clock
              </strong>
            </v-card-title>
            <v-card-text class="text-white text-center">
              <ul class="text-white text-center">
                <li>
                  Access the "Time Clock" from the sidebar menu.
                </li>
                <li>
                  Click the "Clock In" button when starting your shift.
                </li>
                <li>
                  Use the "Clock Out" button when ending your shift or taking a break.
                </li>
                <li>
                  Ensure you always clock in and out correctly for accurate tracking.
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12"
               sm="6"
               class="d-flex">
          <v-card class="info-section-card mb-4 flex-grow-1"
                  color="primary">
            <v-card-title class="text-white text-center">
              <strong>
                Managing Orders
              </strong>
            </v-card-title>
            <v-card-text class="text-white text-center">
              <ul class="text-white text-center">
                <li>
                  Navigate to the "Orders" section from the sidebar.
                </li>
                <li>
                  View all current orders, including pending, in-progress, and completed.
                </li>
                <li>
                  Click on an order to view its details or to update its status.
                </li>
                <li>
                  Use the "Complete Order" button once an order is fulfilled.
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12"
               sm="6"
               class="d-flex">
          <v-card class="info-section-card mb-4 flex-grow-1"
                  color="primary">
            <v-card-title class="text-white text-center">
              <strong>
                Updating Your Profile
              </strong>
            </v-card-title>
            <v-card-text class="text-white text-center">
              <ul class="text-white text-center">
                <li>
                  Click on your profile icon at the top-right corner of the page.
                </li>
                <li>
                  Select "My Profile" from the dropdown menu.
                </li>
                <li>
                  Update your contact details, profile picture, or password as needed.
                </li>
                <li>
                  Click "Save" to apply the changes.
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-btn color="#4db6ac"
             class="white--text mt-4"
             block
             @click="contactManager">
        Contact Manager
      </v-btn>
    </v-container>
  </StaffPageWrapper>
</template>
<script>
import StaffPageWrapper from "@/views/staff/components/StaffPageWrapper.vue";
import {mapGetters, mapActions} from "vuex";

export default {
  components: {
    StaffPageWrapper,
  },
  computed: {
    ...mapGetters(['config', 'user']),
  },
  mounted() {
    this.fetchUser();
  },
  created() {
    this.fetchConfig();
  },
  methods: {
    ...mapActions(['fetchConfig', 'fetchUser']),
    contactManager() {
      alert('Your manager will be notified. You can reach out to them directly via their contact details.');
    },
  },
}
</script>
<style scoped>
.info-card {
  border-radius: 12px;
  padding: 20px;
  text-align: center;
}

.info-section-card {
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 100%;
  min-height: 250px;
}

.v-row {
  flex-wrap: wrap;
}

.v-col {
  display: flex;
}

.v-card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.v-btn {
  border-radius: 12px;
}

.text-white {
  color: #ffffff !important;
}

ul {
  padding-left: 0;
  list-style-position: inside;
}
</style>
