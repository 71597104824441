<template>
  <v-overlay :value="true" absolute>
    <v-progress-circular
        indeterminate
        size="64"
        color="primary"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style scoped>
/* Center the spinner */
.v-overlay__content {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
