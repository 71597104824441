import StaffLogin from "@/views/StaffLogin.vue";
import StaffMain from "@/views/staff/StaffMain.vue";
import StaffTodo from "@/views/staff/todo/StaffTodo.vue";
import Information from "@/views/staff/information/Information.vue";
import Schedule from "@/views/staff/schedule/Schedule.vue";
import Timeclock from "@/views/staff/timeclock/Timeclock.vue";

const staffRoutes = [
    // Login Page
    {
        path: '/staff/login',
        name: 'staff-login',
        component: StaffLogin,
    },
    // Main Landing Page
    {
        path: '/staff',
        name: 'staff',
        component: StaffMain,
    },
    // Todo
    {
        path: '/staff/todo',
        name: 'staff-todo',
        component: StaffTodo,
    },
    // Information
    {
        path: '/staff/information',
        name: 'staff-information',
        component: Information,
    },
    // Schedule
    {
        path: '/staff/schedule',
        name: 'staff-schedule',
        component: Schedule,
    },
    // Timeclock
    {
        path: '/staff/timeclock',
        name: 'staff-timeclock',
        component: Timeclock,
    },

]
export default staffRoutes;