<template>
  <StaffPageWrapper :config="config">

test


  </StaffPageWrapper>
</template>
<script>
import StaffPageWrapper from "@/views/staff/components/StaffPageWrapper.vue";
import {mapGetters, mapActions} from "vuex";

export default {
  components: {
    StaffPageWrapper,
  },

  computed: {
    ...mapGetters(['config', 'user']),
  },
  mounted() {
    this.fetchUser();
  },
  created() {
    this.fetchConfig();
  },
  methods: {
    ...mapActions(['fetchConfig', 'fetchUser']),
  },
}
</script>
<style scoped>
.card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 8px; /* Optional, for rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.card:hover {
  transform: translateY(-10px) scale(1.02);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

</style>