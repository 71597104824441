import Config from "@/views/admin/config/Config.vue";
import Locations from "@/views/admin/locations/Locations.vue";
import Roles from "@/views/admin/roles/Roles.vue";

const managementRoutes = [
    // Config
    {
        path: '/staff/config',
        name: 'staff-config',
        component: Config,
    },
    // Locations
    {
        path: '/staff/locations',
        name: 'staff-locations',
        component: Locations,
    },
    // Roles
    {
        path: '/staff/roles',
        name: 'staff-roles',
        component: Roles,
    },

]
export default managementRoutes;