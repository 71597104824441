<template>
  <StaffPageWrapper :config="config">
    <v-container fluid>
      <v-card class="card-header mb-4">
        <v-card-title class="text-white">
          <strong>
            Manage Roles
          </strong>
        </v-card-title>
        <v-card-text class="text-white">
          Manage roles and assign users to specific roles. The "All, Staff, Manager, Admin" roles are non-editable as
          these are base permission roles.
          <br>
          Select a user to begin role assignment or removal.
        </v-card-text>
      </v-card>
      <v-row>
        <v-col cols="12"
               md="4">
          <v-card class="card-body">
            <v-card-title class="text-white">
              <strong>
                Roles
              </strong>
            </v-card-title>
            <v-list dense
                    class="floating-list"
                    style="background-color: transparent">
              <v-list-item v-for="role in roles"
                           :key="role.id"
                           class="list-item"
                           style="background-color: transparent;">
                <div class="d-flex align-center justify-space-between w-100">
                  <v-icon small
                          color="#4db6ac"
                          class="mr-2"
                          @click.stop="editRole(role)"
                          style="cursor: pointer;"
                          :disabled="role.name === 'All' || role.name === 'Admin' || role.name === 'Manager' || role.name === 'Staff'">
                    mdi-pencil
                  </v-icon>
                  <h3 class="flex-grow-1 text-center m-0 text-white">
                    {{ role.name }}
                  </h3>
                  <v-icon small
                          color="#e57373"
                          @click.stop="deleteRole(role.id)"
                          style="cursor: pointer;"
                          :disabled="role.name === 'All' || role.name === 'Admin' || role.name === 'Manager' || role.name === 'Staff'">
                    mdi-delete
                  </v-icon>
                </div>
              </v-list-item>
            </v-list>
            <v-btn color="#4db6ac"
                   block
                   class="mt-4"
                   @click="showAddRoleDialog = true">
              Add New Role
            </v-btn>
          </v-card>
        </v-col>
        <v-col cols="12"
               md="4">
          <v-card class="card-body">
            <v-card-title class="text-white">
              <strong>
                Users
              </strong>
            </v-card-title>
            <v-card-text>
              <v-text-field v-model="userSearch"
                            label="Search Users"
                            prepend-icon="mdi-magnify"
                            outlined>
              </v-text-field>
              <v-list dense
                      class="floating-list">
                <v-list-item v-for="user in filteredUsers"
                             :key="user.id"
                             @click="selectUser(user)"
                             :class="{'selected-user': selectedUser && selectedUser.id === user.id}"
                             class="list-item"
                             style="background-color: transparent">
                  <v-list-item-title class="text-white">
                    {{ user.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-white">
                    {{ user.email }}
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12"
               md="4">
          <v-card class="card-body">
            <v-card-title class="text-white">
              <strong>
                Assign Roles
              </strong>
            </v-card-title>
            <div v-if="selectedUser">
              <v-card-text>
                <h4 class="text-white mb-3">
                  {{ selectedUser.name }}
                </h4>
                <v-checkbox v-for="role in roles"
                            v-model="selectedUser.roles"
                            :key="role.id"
                            :label="role.name"
                            :value="role.id"
                            :disabled="role.name === 'All'">
                </v-checkbox>
              </v-card-text>
              <v-btn color="#4db6ac"
                     block
                     class="white--text"
                     @click="updateUserRoles">
                Save
              </v-btn>
              <v-btn @click="cancelEditRole"
                     block
                     color="grey">
                Cancel
              </v-btn>
            </div>
            <v-card-text v-else
                         class="text-white">
              <em>
                Select a user to assign roles
              </em>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="showAddRoleDialog"
                max-width="500px">
        <v-card>
          <v-card-title>
            Add New Role
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="newRoleName"
                          label="Role Name"
                          outlined>
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn color="red"
                   text
                   @click="showAddRoleDialog = false">
              Cancel
            </v-btn>
            <v-btn color="#4db6ac"
                   text
                   @click="addRole">
              Add Role
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </StaffPageWrapper>
</template>
<script>
import StaffPageWrapper from "@/views/staff/components/StaffPageWrapper.vue";
import {mapGetters, mapActions} from "vuex";
import axios from "axios";

export default {
  components: {
    StaffPageWrapper,
  },
  data() {
    return {
      roles: [],
      users: [],
      selectedUser: null,
      userSearch: "",
      showAddRoleDialog: false,
      newRoleName: "",
    };
  },
  computed: {
    ...mapGetters(["config", "user"]),
    filteredUsers() {
      return this.users.filter((user) =>
          user.name.toLowerCase().includes(this.userSearch.toLowerCase())
      );
    },
  },
  mounted() {
    this.fetchRoles();
    this.fetchUsers();
  },
  methods: {
    ...mapActions(["fetchConfig"]),
    async fetchRoles() {
      try {
        const token = localStorage.getItem("token");
        const account_id = localStorage.getItem("account_id");
        const response = await axios.get("/roles", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            account_id: account_id,
          },
        });
        this.roles = response.data;
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    },
    async fetchUsers() {
      try {
        const token = localStorage.getItem("token");
        const account_id = localStorage.getItem("account_id");
        const response = await axios.get("/users", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            account_id: account_id,
          },
        });
        this.users = response.data.map((user) => ({
          ...user,
          roles: user.roles.map(role => role.id) || [],
        }));
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    },
    selectUser(user) {
      this.selectedUser = {
        ...user,
        roles: [...user.roles],
      };
    },
    async addRole() {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
            "/roles",
            {name: this.newRoleName},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
        );
        this.roles.push(response.data);
        this.showAddRoleDialog = false;
        this.newRoleName = "";
      } catch (error) {
        console.error("Error adding role:", error);
      }
    },
    async deleteRole(roleId) {
      try {
        const token = localStorage.getItem("token");
        await axios.delete(`/roles/${roleId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.roles = this.roles.filter((role) => role.id !== roleId);
      } catch (error) {
        console.error("Error deleting role:", error);
      }
    },
    async updateUserRoles() {
      try {
        const token = localStorage.getItem("token");
        await axios.post(`/users/${this.selectedUser.id}/assign-roles`, {roles: this.selectedUser.roles}, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        alert("User roles updated successfully");
        this.fetchUsers();
        this.selectedUser = null;
      } catch (error) {
        console.error("Error updating user roles:", error);
      }
    },
    editRole(role) {
      console.log("Edit role:", role);
    },
    cancelEditRole() {
      this.selectedUser = null;
    }
  },
};
</script>
<style scoped>
.card-header {
  border-radius: 12px;
  padding: 20px;
  text-align: center;
}

.card-body {
  background-color: #2a2a2a;
  border-radius: 12px;
  padding: 20px;
}

.floating-list {
  background-color: rgba(108, 102, 98, 0.6);
  border-radius: 12px;
  padding: 8px;
}

.list-item {
  background: rgba(79, 73, 70, 0.8);
  color: #ffffff;
  margin: 4px 0;
  border-radius: 8px;
  padding: 8px;
  transition: background-color 0.2s;
  cursor: pointer;
}

.list-item:hover {
  background-color: rgba(135, 128, 124, 0.8);
}

.selected-user {
  border: 2px solid #4db6ac;
  background-color: rgba(77, 182, 172, 0.2);
  border-radius: 8px;
}

.v-btn {
  background-color: #4db6ac;
  color: white;
}

.v-btn:hover {
  background-color: #00796b;
}
</style>
