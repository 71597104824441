<template>
  <StaffPageWrapper :config="config">
    <v-card-title class="text-white text-center">
      <strong>
        Location Management
      </strong>
    </v-card-title>
    <v-card-text>
      <v-row justify="center"
             align="center">
        <v-col v-for="location in locations"
               :key="location.id"
               cols="12"
               sm="6"
               md="4"
               class="d-flex justify-center">
          <LocationCard :selected-location="selectedLocation"
                        :location="location"
                        @select-location="selectLocation">
          </LocationCard>
        </v-col>
      </v-row>
      <LocationForm v-if="selectedLocation"
                    :selected-location="selectedLocation"
                    :name="'Edit'"
                    @cancel-edit="selectedLocation = null"
                    @save-location="saveLocation">
      </LocationForm>
    </v-card-text>
    <v-row justify="end"
           class="mt-4">
      <v-btn icon
             color="#4db6ac"
             @click="startAddingLocation"
             :disabled="isAddingNewLocation">
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </v-row>
    <LocationForm v-if="isAddingNewLocation"
                  :selected-location="newLocation"
                  :name="'Add'"
                  @cancel-edit="cancelNewLocation"
                  @save-location="addNewLocation">
    </LocationForm>
  </StaffPageWrapper>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import StaffPageWrapper from "@/views/staff/components/StaffPageWrapper.vue";
import axios from "axios";
import LocationCard from "@/views/admin/locations/LocationCard.vue";
import LocationForm from "@/views/admin/locations/LocationForm.vue";

export default {
  components: {
    LocationForm,
    StaffPageWrapper,
    LocationCard,
  },
  data() {
    return {
      user: [],
      locations: [],
      selectedLocation: null,
      isAddingNewLocation: false,
      newLocation: {
        name: '',
        address: '',
        phone: '',
        email: '',
        image: ''
      },
    };
  },
  computed: {
    ...mapGetters(['config', 'user']),
  },
  mounted() {
    this.fetchUser();
  },
  created() {
    this.fetchConfig();
    this.fetchLocations();
  },
  methods: {
    ...mapActions(['fetchConfig', 'fetchUser']),
    async fetchLocations() {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get('/locations', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.locations = response.data;
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    },
    selectLocation(location) {
      this.selectedLocation = {...location};
    },
    async saveLocation() {
      try {
        await axios.put(`/api/locations/${this.selectedLocation.id}`, this.selectedLocation);
        alert("Location updated successfully!");
        this.fetchLocations();
        this.selectedLocation = null;
      } catch (error) {
        console.error("Error saving location:", error);
      }
    },
    startAddingLocation() {
      this.isAddingNewLocation = true;
    },
    cancelNewLocation() {
      this.isAddingNewLocation = false;
      this.newLocation = {name: '', address: '', phone: '', email: '', image: ''};
    },
    async addNewLocation() {
      try {
        const response = await axios.post('/locations', this.newLocation);
        alert("New location added successfully!");
        this.fetchLocations();
        this.cancelNewLocation();
      } catch (error) {
        console.error("Error adding new location:", error);
      }
    },
  },
};
</script>
