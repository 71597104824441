<template>
  <v-card v-if="selectedLocation"
          class="edit-location-card elevation-1">
    <v-card-title class="text-white">
      <strong>
        {{ name }} Location: {{ selectedLocation.name }}
      </strong>
    </v-card-title>
    <v-card-text>
      <v-form ref="editLocationForm">
        <v-row>
          <v-col cols="12"
                 md="6">
            <v-text-field v-model="selectedLocation.name"
                          label="Location Name"
                          class="form-item">
            </v-text-field>
          </v-col>
          <v-col cols="12"
                 md="6">
            <v-text-field v-model="selectedLocation.address"
                          label="Address"
                          class="form-item">
            </v-text-field>
          </v-col>
          <v-col cols="12"
                 md="6">
            <v-text-field v-model="selectedLocation.phone"
                          label="Phone"
                          class="form-item">
            </v-text-field>
          </v-col>
          <v-col cols="12"
                 md="6">
            <v-text-field v-model="selectedLocation.email"
                          label="Email"
                          class="form-item">
            </v-text-field>
          </v-col>
          <v-col cols="12"
                 md="6">
            <v-img :src="selectedLocation.image"
                   aspect-ratio="16/9"
                   class="current-image mb-3">
            </v-img>
            <v-file-input v-model="selectedLocation.image"
                          label="Update Image"
                          accept="image/*"
                          class="form-item">
            </v-file-input>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center">
          <div class="d-flex align-center">
            <v-btn class="teal-button mr-2"
                   @click="$emit('save-location')">
              Save Changes
            </v-btn>
            <v-btn class="ml-3"
                   @click="$emit('cancel-edit')">
              Cancel
            </v-btn>
          </div>
          <v-spacer>
          </v-spacer>
          <v-btn icon
                 :disabled="name === 'Add'"
                 class="ml-3"
                 color="red">
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    selectedLocation: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
}
</script>
<style>

.edit-location-card {
  background: #2a2a2a;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
}

.form-item {
  background: transparent;
  border-radius: 10px;
  color: white;
}

.current-image {
  border-radius: 10px;
  margin-bottom: 10px;
}

.teal-button {
  background-color: #4db6ac !important;
  color: white !important;
}

.teal-button:hover {
  background-color: #00897b !important;
}
</style>